// @import '~antd/es/style/themes/default.less';
// @import 'defaultTheme.json';

html {
  scrollbar-gutter: stable;
}

body {
  * {
    outline: none;
    font-family:
      Segoe UI,
      Frutiger,
      Frutiger Linotype,
      Dejavu Sans,
      Helvetica Neue,
      Arial,
      sans-serif;
  }

  margin: 0;
}

@primary-color: #00677F;@primary-color-hover: #00677F;@primary-color-active: #fafdfd;@primary-color-outline: #00677F;@font-size-base: 12px;@border-color-base: #bfbfbf;@border-color-inverse: #000000;@progress-default-color: #F1B44C;@processing-color: #00677F;@text-color: #495057;@heading-color: #495057;@table-selected-row-bg: #fafdfd;